<template>
	<div class="footer-email-signup">
		<div :class="['fes-form', validationClass]">
			<img
				v-once
				:src="logoUrl"
				alt="Blain's Farm &amp; Fleet Logo"
				loading="lazy"
				width="280"
				height="53"
			/>
			<p class="mt-sm">Be the first to hear about our sales, events, and promotions!</p>
			<form class="flex" @submit.prevent="submitEmail">
				<label :for="emailFieldId" class="hide-aria">
					Email Address
				</label>
				<input
					ref="_email"
					class="fes-input fs-14 flex-adapt"
					v-model.trim="email"
					type="text"
					placeholder="Enter your email..."
					name="email"
					:id="emailFieldId"
				/>
				<ui-button
					class="fes-submit"
					color="soft-blue"
					size="sm"
					type="submit"
					aria-label="Subscribe to newsletter"
					:id="emailSubmitButtonId"
				>
					<i v-if="loading" class="fa fa-spinner fa-spin"></i>
					{{ loading ? '' : 'Sign Up' }}
				</ui-button>
			</form>
		</div>
		<div class="footer-divider"></div>
		<div class="flex-row flex-center fes-social">
			<social-icons
				icon-size="md"
				icon-color="blue"
				class="flex-row flex-between"
			/>
		</div>
		<slot></slot>
		<signup-modal-success
			v-if="showSuccessModal"
			@cancel="closeModal"
			:loading="loading"
		/>
	</div>
</template>

<script>
import EmailSubscriptionService from '../../../services/EmailSubscriptionService.ts';
import SignupModalSuccess from './SignupModalSuccess.vue';
import SocialIcons from '../../../components/SocialIcons.vue';
import { validateEmail } from '../../../helpers/functions.js';

const emailService = new EmailSubscriptionService();

export default {
	name: 'SignUpSection',
	props: {
		logoSize: {
			type: String,
			default: 'md'
		},
		assetCdnUrl: {
			type: String,
			required: true
		},
		idPrefix: {
			type: String,
			required: true
		}
	},
	components: {
		SocialIcons,
		SignupModalSuccess,
	},
	computed: {
		logoUrl() {
			return this.assetCdnUrl + '/assets/images/logos/logo-blue-horizontal.png';
		},
		isEmailValid() {
			return validateEmail(this.email);
		},
		validationClass() {
			if (this.showValidationIcon) {
				return this.isEmailValid ? 'valid' : 'invalid';
			} else {
				return '';
			}
		},
		emailFieldId() {
			return this.idPrefix + 'SignUpSectionEmailInput';
		},
		emailSubmitButtonId() {
			return this.idPrefix + 'SignUpSectionSubmitButton';
		},
	},
	data() {
		return {
			showValidationIcon: false,
			loading: false,
			email: null,
			showSuccessModal: false
		};
	},
	methods: {
		async submitEmail() {
			if (!this.email) return this.$refs._email.focus();
			this.showValidationIcon = true;
			if (!this.isEmailValid) {
				this.handleSignupFailure();
				return;
			}
			const result = await emailService.subscribeEmailToMarketing(this.email);
			if (!result.success) {
				this.handleSignupFailure();
				return;
			}
			this.loading = this.showSuccessModal = true;
			this.handleSignupSuccess();
		},
		handleSignupSuccess() {
			this.email = null;
			blainGtmDataLayer.push({
				'subscribeMethod': 'Footer',
				'event': "email_subscribe"
			});
			this.showValidationIcon = this.loading = false;
		},
		handleSignupFailure() {
			this.loading = false;
		},
		closeModal() {
			this.showSuccessModal = false;
		}
	},
};
</script>

<style lang='less'>
@import '../../../../less/_mixins.less';
.footer-email-signup {
	p {
		color: #4C5271;
	}
	.footer-divider {
		margin: 20px 0;
	}
}

.fes-form {
	padding: 0 10px;
	img {
		max-width: 100%;
	}
	.mobile-styles({
		padding: 0;
	});
}

.fes-input {
	border: solid 1px #CBD0E4;
	border-radius: 6px 0 0 6px;
	color: #4C5271;
	padding: 7px;
}

.fes-submit.ui-btn.ui-btn-sm {
	flex: 0 0 80px;
	font-size: 14px;
	position: relative;
	right: 3px;
}
</style>
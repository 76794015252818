import Vue from 'vue';
import { Customer } from './Customer';
import { IconName } from 'JS/constants/icons';
import { ILocalStoreJson } from './LocalStore';
import { INotification } from './Notifications';
import { IQuickCartSummaryJson } from './Order';

export interface IHeaderJson  {
	cartSummaryJson?: IQuickCartSummaryJson;
	customerHasRegistries: boolean;
	customer?: Customer;
	customerRegion?: string;
	customerName: string;
	deptMenu: IWebSiteMenu[];
	googleApiKey: string;
	headerAds: IHeaderAd[];
	init: boolean;
	isMobileDevice: boolean;
	itemsInCartCount: number;
	localStoreName: string;
	localStoreJson?: ILocalStoreJson;
	loggedIn: boolean;
	notifications?: INotification[];
	notificationsCount: number;
	greeting: string;
}

//Used for the scrolling site messenger
export interface IHeaderAd {
	text: string;
	link?: string;
	icon: IconName;
	endDateTime: string,
	countdown: HeaderAdCountdown;
}

export enum HeaderAdCountdown {
	"None" = 0,
	"After" = 1,
	"Before" = 2,
};

export interface IWebSiteMenu {
	id: string;
	bannerImageUrl: string;
	bannerLinkUrl: string;
	imageUrl: string;
	hasShowMoreLink: boolean;
	children: IWebSiteMenu[];
	name: string;
	url: string;
	limit: number;
	hierLevel: number;
}

export interface IManagedMenu extends Vue {
	menuId: string;
	hide(): void;
}
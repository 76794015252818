<template>
	<div class="footer-customer-care">
		<p class="bold fs-12 show-mobile">Need help?</p>
		<p class="fs-12">
			Get support, place orders, give <br />feedback—we’re here to help!
		</p>
		<div class="fcc-links">
			<div class="flex-row mb-sm">
				<span class="icon icon-email"></span>
				<a href="/customer-care/" class="no-mb">Email Us</a>
			</div>
			<div class="fcc-div show-mobile"></div>
			<div class="flex-row mb-sm">
				<span class="icon icon-phone"></span>
				<a href="/customer-care/" class="no-mb">Call Us 1-800-210-2370</a>
			</div>
			<div class="fcc-div show-mobile"></div>
			<div class="no-mb flex-row">
				<span class="icon icon-edit"></span>
				<a class="no-mb medallia-button jslink">Give Us Feedback</a>
			</div>
		</div>
	</div>
</template> 

<script>
export default {
	name: 'CustomerCare'
};
</script>

<style lang='less'>
@import '../../../../less/_mixins.less';

.footer-customer-care {
	a {
		line-height: 1em;
	}
	p {
		color: #4C5271;
		margin-bottom: 10px;
	}
	span.icon {
		color: @DB;
		font-size: 20px;
		margin-right: 3px;
	}

	.fcc-div {
		background-color: #CBD0E4;
		flex: 0 0 1px;
		width: 1px;
	}

	.mobile-styles({
		border-left: solid 2px #CBD0E4;
		padding-left: 15px;

		.fcc-links {
			
			max-width: 220px;

			a {
				font-size: 12px;
			}
		}
	})
}
</style>
import { render, staticRenderFns } from "./MegaMenuDepartment.vue?vue&type=template&id=64a9c564"
import script from "./MegaMenuDepartment.vue?vue&type=script&setup=true&lang=ts"
export * from "./MegaMenuDepartment.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./MegaMenuDepartment.vue?vue&type=style&index=0&id=64a9c564&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
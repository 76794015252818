import { IQueryParam } from '../../types/Search';

/**
 * Replaces improperly encoded unicode characters with their percent-encoded counterparts
 *
 * Ex: The unicode en-dash (\u2013) encoded as "%u2013" becomes "%E2%80%93"
 *
 * Backstory:
 * I came across an email campaign URL with an improperly encoded unicode en-dash (%u2013)
 * To be a valid URI component, it must be slash encoded. decodeURIComponent throws an exception
 * otherwise. It was causing issues in parseQueryString in this file and in hawksearch.js
 *
 * @param uri The URI/URL to fix
 */
export function replaceImproperlyEncodedUnicodeCharacters(uri: string): string {
	return uri.replace(/%u[0-9A-F]{4}/, match => {
		const charCode = match.slice(2);
		const unicodeCharacter = String.fromCharCode(parseInt(charCode, 16));

		return encodeURIComponent(unicodeCharacter);
	});
}

/**
 * A map of query parameter names to functions that should be used to decode their values. Use this to
 * register custom decoders for params whose values can't be decoded using `decodeURIComponent` alone
 */
const customQueryParamDecoders: Record<string, (value: string) => string> = {
	// We need to replace pluses before decoding the keyword. Reasoning is that pluses are used as whitespace,
	// but there may _also_ be pluses in the query.
	// eg. "Bell+%2B+Howell". If we only use decodeURIComponent, we end up with "Bell+++Howell"
	keyword: value => decodeURIComponent(value.replace(/\+/g, ' ')),

	// tire link urls have their spaces encoded as + by URLSearchParams
	sizeDescription: value => decodeURIComponent(value.replace(/\+/g, ' ')),
};

/**
 * Parses a query string (starting with a "?") into an array of IQueryParam. This is for the product list,
 * _specifically_. **DO NOT** use this elsewhere.
 */
export function parseQueryString(query: string): IQueryParam[] {
	if (query === '') return [];

	query = replaceImproperlyEncodedUnicodeCharacters(query);

	const queries = query
		.slice(1)
		.split('&');

	const queryParams: IQueryParam[] = queries.map(q => {
		const [paramName, unparsedParamValue] = q.split('=');

		const decodeValue = customQueryParamDecoders[paramName] ?? decodeURIComponent;

		const values = !unparsedParamValue
			? null
			: decodeValue(unparsedParamValue)
				// THIS IS A HACK
				// There is at least one brand that has a comma in its name ("Popcorn, Indiana")
				// To make sure it's parsed correctly, we have to replace ", " before splitting the
				// values so we don't end up with something like ['Popcorn', ' Indiana', 'West Bend']
				// and then add the ", " back. I had hope to do this with a simple regex
				// (arg.split(/, !/)), but the browsers regex engine doesn't support that.
				.replace(', ', 'NONSEPCOMMA')
				.split(',')
				.map(value => value.replace('NONSEPCOMMA', ', '));

		return { name: paramName, values };
	});

	return queryParams;
}

/**
 * Pulls an array of comma separated values out of the supplied `query` string at `paramName`.
 * This is for the product list, _specifically_. **DO NOT** use this elsewhere.
 */
export function extractQueryParamValues(query: string, paramName: string): string[] {
	const params = parseQueryString(query);
	const selectedParam = params.find(param => param.name === paramName);

	return selectedParam?.values ?? [];
}

/**
 * Encode a query param value for URL-safety. Augments or overrides some of `encodeURIComponent`'s defaults.
 */
function encodeQueryParamValue(value: string): string {
	return encodeURIComponent(value)
		.replace(/\'/g, "%27") // not encoded by default
		.replace(/%7C/g, "|");
}

/**
 * A map of query parameter names to functions that should be used to encode their values. Use this to
 * register custom encoders for params whose values can't be encoded using `encodeQueryParamValue` alone
 */
const customQueryParamEncoders: Record<string, (value: string) => string> = {
	// encode space as + in keyword. we do this to stay consistent with how spaces get encoded when using the search bar
	keyword: value => encodeQueryParamValue(value).replace(/%20/g, "+"),
	// tire link urls have their spaces encoded as + by URLSearchParams
	sizeDescription: value => encodeQueryParamValue(value).replace(/%20/g, "+"),
};

/**
 * Creates a query string from the given `params`.
 * This is for the product list, _specifically_. **DO NOT** use this elsewhere.
 */
export function buildQuery(params: IQueryParam[]): string {
	return params
		.map(param => {
			const { name, values } = param;

			const encodeValue = customQueryParamEncoders[name] ?? encodeQueryParamValue;

			const encodedValues = values
				?.filter(Boolean)
				.map(encodeValue);

			if (!encodedValues?.length) {
				return null;
			}

			return name + '=' + encodedValues.join(",");
		})
		.filter(Boolean)
		.join("&");
}

// compare these query string param names with ==
export const externalParamAllowNames = [
	'keyword',
	'hawkspellcheck',
	'customerVehicleId',
	'sizeDescription',
	'sectionWidth',
	'aspectRatio',
	'rimSize',
	// Used to force a product list on category pages. See MainWebSite's CatalogController.Category method for details.
	'mode',
	'cref',
	'setstore',
	'gclid',
];

// compare these query string param names with startsWith
export const externalParamAllowPrefixes = [
	"utm_",
	"dtm_",
	"_bta",
	"trk_",
	"blaintm_",
	"gtm_debug",
	"_vis"
];

/**
 * extract known external query params, meaning ones that aren't controlled by
 * facets, paging, bopus, or sort
 */
export function extractExternalQueryParams(params: IQueryParam[]): IQueryParam[] {
	return params.filter(param => {
		let allowed = false;
		externalParamAllowPrefixes.forEach(p => {
			if (param.name.startsWith(p)) {
				allowed = true;
			}
		});

		return allowed || externalParamAllowNames.includes(param.name);
	});
}

/**
 * whether any query param in the string should prevent a page reload
 */
export function shouldReload(newQueryString: string): boolean {
	return parseQueryString(newQueryString)
		.every(param => {
			return param.name == 'cref'
				|| param.name == 'setstore'
				|| param.name == 'gclid'
				|| externalParamAllowPrefixes.some(p => param.name.startsWith(p));
		});
}

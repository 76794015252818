import Debounce from '../helpers/debounce';
import Throttle from '../helpers/throttle';

function setupDebouncedWindowEvents() {
	$(window).on('scroll', Throttle(250, function() {
		$(window).trigger('debouncedScroll');
	}));
	$(window).on('resize', Debounce(250, false, function() {
		$(window).trigger('resizeEnd');
	}));
};

function setupMyLocalStoreLinks($el) {
	const $storeSetters = $el.find("a.set-store");
	if ($storeSetters.length > 0) {
		$el.find("a.set-store").off("click.setstore").on("click.setstore",function(e) {
			e.preventDefault();
			let returnUrl = location.pathname + location.search;
			const baseUrl = $(this).attr("href");
			if (/[?&]setstore=0/.test(returnUrl)) {
				returnUrl = returnUrl.replace(/[?&]setstore=0/, "");
			}
			location.href = baseUrl + "?returnUrl=" + encodeURIComponent(returnUrl);
		});
	}
};

function setupToolTipsAndModals($el) {
	// Global tooltips
	const $toolTips = $el.find(".tooltip");
	if ($toolTips.length > 0) $toolTips.tooltipster();

	// Modals
	const modals = $el.find("div.blain-modal,div.blain-modal-new");
	if (modals.length > 0) modals.blainModal();
};

function setupMedalliaButton() {
	const links = document.querySelectorAll("a.medallia-button");
	if (links) {
		links.forEach(link => {
			link.addEventListener("click", function () {
				if (KAMPYLE_ONSITE_SDK) KAMPYLE_ONSITE_SDK.showForm(6655);
				else alert("A browser extension appears to be blocking this feature!");
			});
		});
	}
}

Blain.GlobalEventListeners = {

	init: function(selector) {
		const $s = $(selector);
		if (!$s.length) return;
		setupMyLocalStoreLinks($s);
		setupToolTipsAndModals($s);
		setupDebouncedWindowEvents();
		setupMedalliaButton();
	}

};
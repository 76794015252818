<template>
	<div class="drawer-head">
		<button
			v-if="currentMenu"
			@click="$emit('go-back')"
			class="drawer-head__back-btn"
			:aria-label="`Go Back. Current Menu: ${menuHasName ? currentMenu.name : 'Departments'}`"
		>
			<icon name="arrow-back" aria-hidden="true" /> Back
		</button>
		<div v-if="!currentMenu" class="drawer-head-top">
			<div class="mb">
				<b-button
					@click="signIn"
					v-if="!loggedIn"
					minimal
					class="mb-sm full-width"
				>
					Sign In
				</b-button>

				<b-button
					href="/account/track-your-order/"
					color="blue"
					variant="secondary"
					minimal
					class="full-width"
					@click="handleMenuClick(0)"
				>
					Track Order
				</b-button>
			</div>
			<div v-if="loggedIn" class="drawer-head__welcome">
				<h2>Hi, {{ customer.firstName }}!</h2>
				<a :href="logoutLink" class="fs-12 blue-link" @click="handleMenuClick(0)">Sign Out</a>
			</div>
		</div>
		<div>
			<div class="uppercase-wide" v-if="headerText && !menuHasName">
				{{ headerText }}
			</div>
			<div class="store-info" v-if="localStore && !currentMenu">
				<span class="local-store">
					{{ localStore.cityAlias }}, {{ localStore.state }}
				</span>
				<span>
					<a href="/store-finder/" class="blue-link" @click="handleMenuClick(0)">Change</a>
				</span>
			</div>
			<div v-if="menuHasName" class="active-item">
				{{ currentMenu.name }}
			</div>
			<div v-if="!localStore && !currentMenu">
				<a href="/store-finder/" class="blue-link" @click="handleMenuClick(0)">Set My Local Store</a>
			</div>
		</div>
		<b-button
			v-if="currentMenu && currentMenu.url"
			color="blue"
			variant="secondary"
			minimal
			:href="currentMenu.url"
			class="mt-sm full-width"
			@click="handleMenuClick(currentMenu.hierLevel)"
		>
			Shop All
		</b-button>
	</div>
</template>

<script>
import BButton from 'JS/components/BButton.vue';
import Icon from 'JS/components/Icon.vue';
export default {
	name: 'MobileMenuHeader',
	components: {
		BButton,
		Icon,
	},
	props: {
		currentMenu: [Object, Array],
		customer: {
			type: Object
		},
		localStore: {
			type: Object
		},
		loggedIn: {
			type: Boolean
		},
		headerText: String,
	},
	computed: {
		logoutLink() {
			const path = `/account/logout/`;
			if (typeof window === 'undefined' || window.location.pathname === '/') return path;
			return `${path}?refUrl=${encodeURIComponent(window.location.pathname)}`;
		},
		menuHasName() {
			return this.currentMenu && this.currentMenu.name;
		}
	},
	methods: {
		signIn() {
			this.handleMenuClick(0);
			Blain.Login.showSideMenu('login', true);
		},
		handleMenuClick(menuLevel) {
			window.blainGtmDataLayer.push({
				'menuName': "main",
				'menuLevel': menuLevel,
				'event': "menu_click"
			});
		}
	}
};
</script>

<style lang='less' scoped>
@import "~lessRoot/_mixins.less";
.drawer-head {
	background-color: #fff;
	font-size: 18px;
	border-bottom: 2px solid tint(@CL, 80%);
	padding: 15px 10px 20px 10px;

	&__welcome {
		display: flex;
		justify-content: space-between;
	}

	.blue-link {
		color: @BB;
		cursor: pointer;
		font-size: 13px;
		font-weight: 500;
		text-decoration: none;
		align-self: end;
	}

	&__back-btn {
		.button-reset();
		cursor: pointer;
		color: @BB;
		font-size: 14px;
		font-weight: bold;
		max-width: 70px;
		.icon {
			font-weight: lighter;
			font-size: 11px;
		}
	}
	.drawer-head-top {
		margin-bottom: 20px;
		h2 {
			color: shade(@CL, 80%);
			font-size: 18px;
			font-weight: bold;
			line-height: 1;
			margin-bottom: 0;
		}
	}
	.uppercase-wide {
		color: shade(@CL, 40%);
		font-size: 12px;
		text-transform: uppercase;
		line-height: 1.25;
	}
	.store-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.local-store {
			font-size: 18px;
			line-height: 1.25;
			font-weight: bold;
			color: shade(@CL, 80%);
		}
	}
	.active-item {
		margin-top: 6px;
		font-size: 14px;
		line-height: 16px;
		color: #4c5271;
		white-space: nowrap;
		overflow: hidden;
	}
}
</style>
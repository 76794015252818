import { AnyObject } from "JS/types/Generic";
import { getPriceMode } from "../types/Product";
import { ICarouselClickData, ICarouselClickGtmData, ICarouselViewData, ICarouselViewGtmData, IProductCarousel, IProductCarouselProduct } from "../types/Carousel";


/**
 * Creates a CarouselViewData object to be sent to the back-end for carousel view tracking
 *
 * @param carousel The carousel to be tracked
 * @param url URL to track the view with
 */
export function createCarouselViewData(carousel: IProductCarousel, url?: string): ICarouselViewData {
	return {
		type: carousel.carouselType,
		carouselName: getCarouselName(carousel),
		url: url || location.pathname + location.search,
		position: null,
	};
}

/**
 * Creates a CarouselViewGtmData object to be sent to GTM for carousel view tracking
 *
 * @param carousel The carousel to be tracked
 */
export function createCarouselViewGtmData(
	carousel: IProductCarousel,
	customCarouselID?: string,
): ICarouselViewGtmData {
	return {
		itemListID: customCarouselID || getCarouselID(carousel),
		itemListName: getCarouselName(carousel),
		carouselProducts: carousel.products
	};
}

/**
 * Gets the carousel list ID from its type
 *
 * @param carousel The product carousel
 */
export function getCarouselID(carousel: IProductCarousel): string {
	return "carousel_" + carousel.carouselType.toString();
}

/**
 * Gets the carousel name from its name or title
 *
 * @param carousel The product carousel
 */
export function getCarouselName(carousel: IProductCarousel): string {
	return carousel.name || carousel.title;
}

/**
 * Gets the carousel name from its name or title
 *
 * @param carousel The product carousel
 * @param sku The SKU of the product for which we're locating the index of
 */
export function getCarouselProductIndex(carousel: IProductCarousel, sku: string): number {
	return carousel.products.findIndex(x => x.blainNumber == sku) || 0;
}

/**
 * Creates a CarouselClickData object to be sent to the back-end for carousel product click tracking
 *
 * @param product The product that was clicked
 * @param carousel The carousel that contains the product
 */
export function createCarouselClickData(
	product: IProductCarouselProduct,
	carousel: IProductCarousel,
	customData?: AnyObject,
	url?: string,
): ICarouselClickData  {
	const data = !!customData && typeof customData === 'object'
		? customData
		: {};

	return {
		blainNumber: product.blainNumber,
		currentPrice: product.price.currentPrice,
		carousel: createCarouselViewData(carousel, url),
		itemIndex: getCarouselProductIndex(carousel, product.blainNumber),
		...data
	};
}

/**
 * Creates a CarouselClickGtmData object to be sent to GTM for carousel product click tracking
 *
 * @param product The product that was clicked
 * @param carousel The carousel that contains the product
 */
export function createCarouselClickGtmData(
	product: IProductCarouselProduct,
	carousel: IProductCarousel,
	customCarouselID?: string,
): ICarouselClickGtmData {
	return {
		itemListID: customCarouselID || getCarouselID(carousel),
		itemListName: getCarouselName(carousel),
		currentPrice: product.price.currentPrice,
		belowMap: product.price.isBelowMAP,
		priceMode: getPriceMode(product.price),
		blainNumber: product.blainNumber,
		vendorBrand: product.vendorBrand,
		productName: product.productName,
		index: getCarouselProductIndex(carousel, product.blainNumber)
	};
}

/**
 * Abstracts the behavior required to make tracking links more reliable and user-friendly
 *
 * @param e MouseEvent. Must be emitted from an HTMLAnchorElement.
 * @param handler Event handler with the actual tracking logic.
 */
export function handleTrackingLinkClick(e: MouseEvent, handler: (e: MouseEvent) => void): void {
	const willOpenInSameTab = !e.shiftKey && !e.ctrlKey && !e.metaKey && !e.altKey;
	if (willOpenInSameTab) {
		e.preventDefault();
	}
	handler(e);
	if (willOpenInSameTab) {
		const link = e.currentTarget;
		if (!(link instanceof HTMLAnchorElement)) {
			return;
		}
		setTimeout(() => {
			window.location.assign(link.href);
		}, 100);
	}
}

export function createTrackingLinkClickHandler(handler: (e: MouseEvent) => void): (e: MouseEvent) => void {
	return e => handleTrackingLinkClick(e, handler);
}
import { addPasswordStrengthValidation, passwordMeetsMinimumRequirements } from './password-strength';

require('tooltipster');
require('../vendor/masked-input.js');
require('../vendor/credit-card-validation.js');
require('jquery-validation');

Blain.Forms = {
	init: function() {

		// Set jQuery validator defaults
		if ($.validator) {
			$.validator.setDefaults({

				// Ignore fields with "novalidate" class
				ignore: ".novalidate",

				// Scroll to the first invalid element, if validation fails (and if the form doesn't have 'blain-form-fixed' class on it)
				invalidHandler: function (form, validator) {
					if (!validator.numberOfInvalids()) return;
					if (!$(form.target).hasClass("blain-form-fixed")) {
						$("html, body").animate({
							scrollTop: Math.max($(validator.errorList[0].element).offset().top - 100, 0)
						}, 600);
					}
				},

				// Track errors using tooltipster
				errorPlacement: function (error, element) {

					const $element = $(element);
					if ($element.hasClass("tooltipstered")) {
						const lastError = $element.data("lastError");
						const newError = $(error).text();

						$element.data("lastError", newError);

						if (newError !== "" && newError !== lastError) {
							$element.tooltipster("content", newError);
						}
					}

				},

				// Hide tooltipster elements when validation passes
				success: function (label, element) {
					const $element = $(element);
					if ($element.hasClass("tooltipstered")) {
						$element.tooltipster("hide");
					}
				}

			});

			// Validator message defaults
			/*
			$.validator.messages.required = 'This field is required.';
			$.validator.messages.maxlength = $.validator.format("Exceeds {0}-character maximum");
			$.validator.messages.minlength = $.validator.format("At least {0} characters required");
			$.validator.messages.rangelength = $.validator.format("{0}-{1} characters required");
			$.validator.messages.range = $.validator.format("Value between {0}-{1} required");
			$.validator.messages.max = $.validator.format("Maximum value is {0}");
			$.validator.messages.min = $.validator.format("Minimum value is {0}");
			*/

			// Validator custom rules

			// Max textarea length
			let msg = $.validator.messages.maxlength;
			jQuery.validator.addMethod("maxtextarea", function (value, element, param) {
				if (value === undefined || value === "" || value === null) return true;
				const len = value.length;
				if (len > param) {
					msg = `Exceeds ${param}-character max by ${(len - param).toString()}`;
					return false;
				}
				return true;
			}, function () {
				return msg; 
			});

			// Replacement for bad "email" validation
			jQuery.validator.addMethod("validEmail", function (value) {
				if (!value) return true;
				if (value.endsWith(".con") ||
					value.endsWith("gmai.com") ||
					value.endsWith("comcast.nt") ||
					value.endsWith(".coim") ||
					value.endsWith(".vom")) return false;
				return /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
			}, "Please enter a valid email address.");

			// At least one letter and one number validation
			jQuery.validator.addMethod('oneNumAndLetter', function (value) {
				return (!value) || passwordMeetsMinimumRequirements(value);
			}, 'Please enter at least 1 uppercase letter, 1 lowercase letter and 1 number');

			// Validate card expiration date
			jQuery.validator.addMethod("expdate", function (value,element) {
				if (this.optional(element)) return true;
				const tmp = value.split("/");
				if (tmp.length === 2) {
					const month = parseInt(tmp[0]) - 1;
					const year = parseInt("20" + tmp[1]);
					const now = new Date();
					if (year > now.getFullYear() && month < 12) return true;
					if (year === now.getFullYear() && month >= now.getMonth() && month < 12) return true;
				}
				return false;
			}, "Expiration date is invalid.");

			// Validate CVV
			jQuery.validator.addMethod("validcc", function (value, element) {
				return this.optional(element) || $(element).data('blain-cardvalid');
			}, "Credit card number is not valid");

			// Validate CVV
			jQuery.validator.addMethod("cvv3", function (value, element) {
				return this.optional(element) || /^[0-9]{3}$/.test(value);
			}, "Security code is a 3 digit number");

			// Validate CVV
			jQuery.validator.addMethod("cvv4", function (value, element) {
				return this.optional(element) || /^[0-9]{4}$/.test(value);
			}, "Security code is a 4 digit number");

			// Validate US Phone number
			jQuery.validator.addMethod("phoneUS", function (phone_number, element) {
				phone_number = phone_number.replace(/\s+/g, "");
				return this.optional(element) || phone_number.length > 9 &&
					phone_number.match(/^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9])-?\d{4}$/);
			}, "Please specify a valid phone number");

			// Validate multiple emails
			jQuery.validator.addMethod("multiemails", function(value, element) {
					 if (this.optional(element))
						 return true;
					 const emails = value.split(/[,]+/);
					 let valid = true;
					 for (const i in emails) {
						 value = emails[i];
						 valid = valid &&
								 jQuery.validator.methods.email.call(this, $.trim(value), element);
					 }
					 return valid;
				 },
			
			   jQuery.validator.messages.multiemails
			);

		}


		// Initialize form tooltip validation
		Blain.Forms.initFormsTooltips($("form.tooltip-validation input"));

		// Setup automatic masked input fields
		Blain.Forms.initMaskedInputs($("body"));

		// add zxcvbn validation and password strength indicator
		Blain.Forms.initNewPasswordValidation();
	},

	reInit: function($wrapper) {
		if ($wrapper.length) {
			Blain.Forms.initFormsTooltips($wrapper);
			Blain.Forms.initMaskedInputs($wrapper);
		} else {
			Blain.Forms.initFormsTooltips($("form.tooltip-validation input"));
			Blain.Forms.initMaskedInputs($("body"));
		}
	},

	initFormsTooltips: function ($obj) {
		// Tooltip form validation setup
		$obj.not("input[type=hidden]").tooltipster({
			trigger: "custom",
			onlyOne: true,
			position: "top",
			viewportAware: false
		});

		// Tooltips will appear on focus and disappear on blur
		$obj.on("focus.tooltip", function () {
			const $elem = $(this);
			const errorStr = $elem.data("lastError");
			if (errorStr !== null && errorStr !== "") $elem.tooltipster("show");
		}).on("blur.tooltip", function () {
			$(this).tooltipster("hide");
		});
	},

	initMaskedInputs: function ($container) {
		$container.find("input.phonefield").on('change', function (e) {
			const x = e.target.value.replace(/\D/g, '').match(/([2-9]\d{0,2})(\d{0,3})(\d{0,4})/);
			if (typeof (x) === "undefined" || x == null) return;
			e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
		});

		$container.find("input.expdatefield").on('input', function (e) {
			const x = e.target.value.replace(/\D/g, '').match(/^(0[1-9]|1[0-2])([0-9]{4}|[0-9]{2})$/);
			if (typeof (x) === "undefined" || x == null) return;
			e.target.value = !x[2] ? x[1] : x[1] + '/' + x[2];
		});

		//$container.find("input.phonefield").mask("(999) 999-9999");
		//$container.find("input.expdatefield").mask("99/99");
		$container.find("input.datefield").mask("99/99/9999");
		$container.find("input.zipfield").mask("99999", { placeholder: "" });
		$container.find("input.cvvfield").mask("?9999", { placeholder: "" });
		$container.find("input.ccfield").mask("?9999999999999999", { placeholder: "" });
		$container.find("input.qtyfield").mask("?999", { placeholder: "" });

		// Current workaround for getting input mask to work correctly with jquery validator
		$container.find("form:not(.novalidate)").find("input.phonefield:not(.novalidate), input.zipfield:not(.novalidate), input.expdatefield:not(.novalidate), input.cvvfield:not(.novalidate)").on("blur.revalidate", function () {
			$(this).valid();
		});

		const setCardType = function (type) {
			const $ccCsCardType = $("#ccCsCardType");
			if ($ccCsCardType.length > 0) {
				switch (type) {
					case "visa":
						$ccCsCardType.val("001");
						break;
					case "mastercard":
						$ccCsCardType.val("002");
						break;
					case "amex":
						$ccCsCardType.val("003");
						break;
					case "discover":
						$ccCsCardType.val("004");
						break;
				}
			}
		};

		// Initialize credit card validator
		const $ccfields = $container.find("input.ccfield");
		if ($ccfields.length > 0) {
			const tmpstr = $ccfields.data('blain-acceptcards');
			const creditCards = tmpstr ? tmpstr.split(",") : [];
			$ccfields.validateCreditCard(function (r) {
				const elem = $(this);
				if (r.card_type) {
					$(document).trigger("cardTypeFound", r.card_type.name);
					elem.addClass(`cc-${r.card_type.name}`).removeClass("cc-blank");
					setCardType(r.card_type.name);
					if (r.luhn_valid && r.length_valid) {
						elem.addClass("valid").removeClass("error");
						elem.data('blain-cardvalid', true);
					} else {
						if (r.length_valid) elem.removeClass("valid").addClass("error");
						else elem.removeClass("valid error");
						elem.data('blain-cardvalid', false);
					}
				} else {
					elem.removeClass("cc-amex cc-blain cc-discover cc-mastercard cc-visa");
					if (elem.val().length === 0) {
						const cardClass = elem.data("blain-cardclass");
						if (cardClass) {
							elem.removeClass("cc-blank").addClass(`cc-${cardClass}`);
							setCardType(cardClass);
						} else elem.addClass("cc-blank");
					} else {
						elem.addClass("cc-blank");
					}
					if (r.length_valid || r.luhn_valid) elem.removeClass("valid").addClass("error");
					else elem.removeClass("error valid");
					elem.data('blain-cardvalid', false);
				}
			}, { accept: creditCards });
		}
	},

	initNewPasswordValidation: function() {
		const $newPasswordField = $("#newPassword");

		if ($newPasswordField.length) {
			//addPasswordStrengthValidation($newPasswordField.parent());
		}
	},

	nonceExists: function() {
		const token = $('input[name="__RequestVerificationToken"]');
		return token.length > 0;
	}
};
<template>
	<div class="mega-menu-automotive-department full-width">
		<div class="flex">
			<div class="flex-adapt">
				<ul class="flex mmd-automotive-links">
					<li class="mmd-automotive-link">
						<span class="icon icon-tire mmd-automotive-icon-lg" aria-hidden="true"></span>
						<h2 class="fs-16 bold mmd-automotive-link__title">Tires</h2>
						<p class="fs-14">Find tires that fit your vehicle and get recommendations for you using our Tire Buying Guide.</p>
						<ui-button
							color="soft-blue-outline block"
							href="/tires/"
							@click="handleMenuClick(0)"
							inline
						>
							Shop All Tires
							<span class="icon icon-chevron-right dark-blue" aria-hidden="true"></span>
						</ui-button>
					</li>
					<li class="mmd-automotive-link">
						<span class="icon icon-battery mmd-automotive-icon-lg" aria-hidden="true"></span>
						<h2 class="fs-16 bold mmd-automotive-link__title">Batteries</h2>
						<p class="fs-14">Shop batteries by vehicle and get it installed at your service center, or pick it up at your local store.</p>
						<ui-button
							color="soft-blue-outline block"
							href="/batteries/"
							@click="handleMenuClick(0)"
							inline
						>
							Shop All Batteries
							<span class="icon icon-chevron-right dark-blue" aria-hidden="true"></span>
						</ui-button>
					</li>
					<li class="mmd-automotive-link">
						<span class="icon icon-wrench mmd-automotive-icon-lg" aria-hidden="true"></span>
						<h2 class="fs-16 bold mmd-automotive-link__title">Services &amp; Repair</h2>
						<ul class="fs-14 mmd-automotive-services">
							<li>Oil Changes</li>
							<li>Wheel Alignments</li>
							<li>Brake &amp; Suspension Inspections</li>
							<li>Electrical Tests</li>
							<li>We Install What We Sell</li>
						</ul>
						<ui-button
							color="soft-blue-outline block"
							href="/automotive-service-repair/"
							@click="handleMenuClick(0)"
							inline
						>
							Learn More
							<span class="icon icon-chevron-right dark-blue" aria-hidden="true"></span>
						</ui-button>
					</li>
				</ul>
			</div>
			<a
				v-if="menuItem.bannerImageUrl"
				:href="menuItem.bannerLinkUrl"
				class="mega-menu-automotive-department-image hide-desktop-min"
				@click="handleMenuClick(0)"
			>
				<img :src="menuItem.bannerImageUrl" :alt="menuItem.name" />
			</a>
		</div>
	</div>
</template>

<script>
import UiButton from 'Src/js/components/UiButton.vue';
import Vue from 'vue';
Vue.use(UiButton);
export default {
	components: { UiButton },
	name: 'MegaMenuAutomotiveDepartment',
	props: {
		menuItem: {
			type: Object,
			required: true
		}
	},
	methods: {
		handleMenuClick(menuLevel) {
			window.blainGtmDataLayer.push({
				'menuName': "main",
				'menuLevel': menuLevel,
				'event': "menu_click"
			});
		}
	}
};
</script>

<style lang="less">
@import '~@less/_mixins.less';
.mmd-automotive-link {
	background-color: #F7F8FB;
	border-bottom: solid 2px #CBD0E4;
	padding: 20px;
	flex: 1 1 30%;
	margin-right: 20px;
	.ui-btn:hover {
		text-decoration: none;
	}
	.icon-chevron-right {
		position: relative;
		top: 2px;
	}
	&__title {
		margin-bottom: 6px;
	}
}
.mmd-automotive-icon-lg {
	border-bottom: solid 5px @LB;
	display: block;
	color: @LB;
	margin-bottom: 20px;
	padding-bottom: 5px;
	font-size: 33px;
	width: 52px;
}
.mmd-automotive-services {
	margin-bottom: 15px;
	li {
		margin-bottom: 5px;
	}
	li::before {
		content: "\2022";
		color: @CL;
		font-weight: bold;
		font-size: 22px;
		line-height: 16px;
		display: inline-block;
		width: 12px;
		position: relative;
		top: 3px;
	}
}

.mega-menu-automotive-department {
	background-color: #FFF;
	top: 0; bottom: 0; left: 100%;
	padding: 20px;
	position: absolute;
	min-height: 0;
	max-width: calc(~"100vw - 240px");
	width: 1240px;
	a {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

	&-image img {
		width: 300px;
	}
}
</style>
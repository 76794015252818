import { onMounted, onUnmounted, watch, WatchSource } from "vue";
import { preventBodyScroll } from "JS/utilities/dom";

/**
 * Makes the page unscrollable when `shouldPrevent` is true and re-enables scrolling when false.
 * Ensures the the page is scrollable after the consuming component
 *
 * If shouldPrevent is not provided, the page will be unscrollable until the component is unmounted.
 */
export function usePreventBodyScroll(shouldPrevent: WatchSource<boolean> = () => true) {
	let restoreBodyScroll: (() => void) | null = null;

	// NOTE: originally, this used an immediate watch, so onMounted wasn't necessary but
	// that caused issues during HMR where the watch would be triggered before the old
	// component instance was unmounted. not sure if this is a bug in Vue 2.7, webpack,
	// or something else.
	onMounted(() => {
		const initialShouldPrevent = typeof shouldPrevent === 'function'
			? shouldPrevent()
			: shouldPrevent.value;

		if (initialShouldPrevent) {
			restoreBodyScroll = preventBodyScroll();
		}
	});

	watch(shouldPrevent, prevent => {
		restoreBodyScroll?.();
		restoreBodyScroll = null;
		if (prevent) {
			restoreBodyScroll = preventBodyScroll();
		}
	});

	onUnmounted(() => restoreBodyScroll?.());
}